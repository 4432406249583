import { Routes } from '@angular/router';

import { SupplierComponent } from './../SupplierComponent/supplier.component';
import { SupplierListComponent } from './../SupplierComponent/supplierList.component';
import { SupplierAddComponent } from './../SupplierComponent/supplierAdd.component';
import { SupplierEditComponent } from './../SupplierComponent/supplierEdit.component';
import { SupplierContactListComponent } from './../SupplierComponent/supplierContactList.component';
import { SupplierContactAddComponent } from './../SupplierComponent/supplierContactAdd.component';
import { SupplierContactEditComponent } from './../SupplierComponent/supplierContactEdit.component';
import { MsalGuard } from '@azure/msal-angular';

export const SUPPLIER_ROUTES: Routes = [
  {
    path: 'Suppliers',
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        component: SupplierListComponent,
      },
      {
        path: 'Add',
        component: SupplierAddComponent,
      },
      {
        path: ':supplierId',
        component: SupplierComponent,
        children: [
          {
            path: 'Edit',
            component: SupplierEditComponent,
            children: [
              {
                path: '',
                component: SupplierContactListComponent,
              },
              {
                path: 'Contact/Add',
                component: SupplierContactAddComponent,
              },
              {
                path: 'Contact/:contactId/Edit',
                component: SupplierContactEditComponent,
              },
            ],
          },
        ],
      },
    ],
  },
];
