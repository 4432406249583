<div class="container">
    <div class="screen-title text-center">Edit Supplier</div>

    <div class="row">
        <div class="col-sm-12">
            <supplier-edit-form [model]="model | async" (onSave)="saveSupplier($event)" (onCancel)="cancel()" [showAlert]="showAlert" [isNew]="false" (onGoToSupplier)="goToSupplier()"></supplier-edit-form>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
