import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { ISupplierModel } from '@/models/supplier.models';
import { UI_MASKS } from '@/utils/uiMasks';

@Component({
  selector: 'supplier-edit-form',
  templateUrl: './supplierEditForm.template.html',
  host: {'class': 'supplier-edit-form-component'}
})
export class SupplierEditFormComponent {
  @Input()
  model: ISupplierModel;

  @Input()
  showAlert: boolean = false;

  @Input()
  isNew: boolean;

  @Output()
  onSave: EventEmitter<ISupplierModel> = new EventEmitter<ISupplierModel>();

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onGoToSupplier: EventEmitter<void> = new EventEmitter<void>();

  public saved: Subject<any> = new Subject();
  private usState: string;
  private usZip: string;
  private canState: string;
  private canZip: string;

  constructor(public uiMasks: UI_MASKS) {}

  ngOnInit(): void {
    this.saved.next('false');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      if(!this.model.type){
        this.model.type="Materials";
      }
      if (!this.model.address.country) {
        this.model.address.country = 'United States';
      }
    }
  }
  saveSupplier(): void {
    this.onSave.emit(this.model);
    this.saved.next('true');
  }

  setSupplierType(type) {
    this.model.type = type;
    this.model.category = 'other';
  }

  setCategory(e) {
    this.model.category=e;
  }

  cancel(): void {
    this.onCancel.emit(null);
    this.saved.next('true');
  }

  goToSupplier(): void {
    this.onGoToSupplier.emit(null);
  }

  toggleActive(): void {
    this.model.isActive = !this.model.isActive;
  }

  // save and restore entered values if switching back and forth between countries
  validate(e): void {
    if (e === 'United States') {
      this.canState = this.model.address.state;
      this.canZip = this.model.address.zip;
      this.model.address.state = this.usState || null;
      this.model.address.zip = this.usZip || null;
    } else {
      this.usState = this.model.address.state;
      this.usZip = this.model.address.zip;
      this.model.address.state = this.canState || null;
      this.model.address.zip = this.canZip || null;
    }
    this.model.address.country = e;
  }

}
