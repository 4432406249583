import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StateManager } from './../../state/stateManager';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MessageService } from '@/services/message.service';
import * as routerUtils from '@/utils/routerUtils';
import { SupplierModel } from './../../models/supplier.models';
import { BaseSmartComponent } from '../base.component';
import { SupplierService } from '@/services/supplier.service';

@Component({
    selector: 'supplier',
    templateUrl: './supplier.template.html',
    host: {'class': 'supplier-component'}
})
export class SupplierComponent extends BaseSmartComponent implements OnDestroy {
    private _id: string;
    private name: string;
    private routerEvents: Subscription;

    constructor(
      private state: StateManager,
      private router: Router,
      private route: ActivatedRoute,
      messageService: MessageService,
      private supplierService: SupplierService
    ) {
      super(messageService);
    }

    ngOnInit(): void {
      this.watchSubscription(this.state.getActiveSupplier().subscribe((s) => {
        this.name = s.name;
      }));

      this.routerEvents = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => this.loadSupplier());

      this.loadSupplier();
    }

    override ngOnDestroy(): void {
      this.routerEvents.unsubscribe();
      this.state.setActiveSupplier(new SupplierModel());
      super.ngOnDestroy();
    }

    private loadSupplier(): void {
        this._id = routerUtils.getRouteParameter(this.route, 'supplierId');
        if (this._id) {
            this.supplierService.getSupplier(this._id);
        } else {
            // todo route back to suppliers list
        }
    }
}
