import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseSmartComponent } from './../base.component';
import { StateManager } from '@/state/stateManager';
import * as routerUtils from '@/utils/routerUtils';

import { MessageService } from '@/services/message.service';
import { SupplierService } from '@/services/supplier.service';

import { ISupplierModel, SupplierModel } from '@/models/supplier.models';
@Component({
    selector: 'supplier-add',
    templateUrl: './supplierAdd.template.html',
    host: {'class': 'supplier-add-component'}
})

export class SupplierAddComponent extends BaseSmartComponent implements OnInit {
    public model : Observable<ISupplierModel>;
    private _supplierListRoute : ActivatedRoute;
    public showAlert: boolean = false;
    private duplicateSupplier : ISupplierModel;

    constructor(
        private router: Router, 
        private route: ActivatedRoute, 
        private supplierService : SupplierService, 
        private state : StateManager, 
        messageService : MessageService,
        private location : Location
        ) {
        super(messageService);
    }

    ngOnInit() : void {
        const self = this;

        self.model = self.state.getActiveSupplier();
        self.state.setActiveSupplier(new SupplierModel());

        self._supplierListRoute = routerUtils.getParentRoute(self.route);
    }

    public saveSupplier(newModel: ISupplierModel) : void {
        const self = this;
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
		let currentLocation = currentUser.locationId;
        newModel.location = currentLocation;

        self.supplierService.updateSupplier(newModel)
            .then(() => {
                this.showAlert = false;
                self.router.navigate(['.'], { relativeTo: self._supplierListRoute });
            })
            .catch((err) => {
                this.showAlert = true;
                let error = err.error;
                this.duplicateSupplier = error.record;
            });
    }

    public cancel() : void {
        this.location.back();
    }

    public goToSupplier() {
        this.router.navigate([this.duplicateSupplier._id, 'Edit'], { relativeTo: routerUtils.getParentRoute(this.route) });
    }

};
