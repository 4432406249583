import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { LocalStorageService } from '@/services/localStorage.service';

import { StateManager } from '@/state/stateManager';
import { BaseSmartComponent } from '@/components/base.component';
import { MessageService } from '@/services/message.service';
import * as Utility from '@/models/utility.models';
import { SupplierService } from '@/services/supplier.service';
import { ListHeaderService } from '@/services/listHeader.service';
import { DataManipulationService } from '@/services/dataManipulation.service';
import { ISupplierModel, SupplierModel, SupplierStatus, supplierLabel } from '@/models/supplier.models';
import { handleError } from '@/utils/errors';
import { FilterDescription, FilterValue } from '@/models/filter.models';
import { RouterHistoryService } from '@/services/router-history.service';
import { AuthService } from '@/services/auth.service';

@Component({
  selector: 'supplier-list',
  templateUrl: './supplierList.template.html',
  host: {'class': 'supplier-list-component'},
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ]
})
export class SupplierListComponent extends BaseSmartComponent implements OnInit, OnDestroy {
  @ViewChild('supplierSearch', {static: true})
  supplierSearch: Utility.IFocusable;

  public showInactive: boolean = false;
  public searchTerm: string = null;
  public sortDirection: number = 1;
  public currentSort: string = 'name';
  public isLoading: boolean = true;
  public filterOptions: FilterDescription[];
  public filters: FilterValue[] = [];

  supplierLabel: (status: SupplierStatus) => string = supplierLabel;

  private model: Subject<Array<ISupplierModel>> = new Subject<Array<ISupplierModel>>();
  private supplierSet = [];
  private pageNumber: number = 0;
  private sortField: string = 'name';
  private scrollPosition: number = 0;
  private locationSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private state: StateManager,
    private authService: AuthService,
    private supplierService: SupplierService,
    private dataManipulationService: DataManipulationService<ISupplierModel>,
    messageService: MessageService,
    private history: RouterHistoryService,
    private localStorageService: LocalStorageService,
    private listHeaderService: ListHeaderService
  ) {
    super(messageService);
  }

  ngOnInit(): void {
    this.dataManipulationService.initialize(
      {},
      {
        'name': (source: Array<ISupplierModel>, order: number): Array<ISupplierModel> => {
          return source.sort((a, b) => {
            if (a.name > b.name) {
              return 1 * order;
            } else if (a.name < b.name) {
              return -1 * order;
            }
            return 0;
          });
        }
      });

    this.watchSubscription(this.dataManipulationService.connectDataSource(this.state.getSuppliersList(), this.model));
    this.reloadSuppliersList(this.filters);
    this.setFilterOptions();

    this.locationSub = this.localStorageService.watchStorage().subscribe(changed => {
      this.resetList();
      this.reloadSuppliersList(this.filters);
    });
  }

  override ngOnDestroy(): void {
    this.locationSub.unsubscribe();
    super.ngOnDestroy();
  }

  public addSupplier(): void {
    this.state.setActiveSupplier(new SupplierModel());
    this.router.navigate(['Add'], { relativeTo: this.route });
  }

  public search(): void {
    this.resetList();
    this.reloadSuppliersList(this.filters);
  }

  filterToggle(value: string): void {
    switch (value) {
      case 'showInactive':
        this.showInactive = !this.showInactive;
        break;
      default:
        break;
    }
    this.resetList();
    this.reloadSuppliersList(this.filters);
  }

  public sortList(e): void {
    this.sortField = e.field;
    this.sortDirection = e.sortDirection;
    this.currentSort = e.currentSort;
    this.resetList();
    this.reloadSuppliersList(this.filters);
  }

  public filterTheList(filters: FilterValue[]): void {
    this.isLoading = true;
    this.filters = filters;
    this.resetList();
    this.reloadSuppliersList(this.filters);
  }

  public onScroll(e): void {
    // don't reload on scroll up
    if (e.currentScrollPosition > this.scrollPosition) {
      this.scrollPosition = e.currentScrollPosition;
      if (!this.isLoading) {
        this.pageNumber++;
        this.reloadSuppliersList(this.filters);
      }
    }
  }

  private editSupplier(id: string): void {
    this.router.navigate([id, 'Edit'], { relativeTo: this.route });
  }

  private reloadSuppliersList(filters: FilterValue[]): void {
    const statuses = filters.filter(f => f.filter === 'statusFilter').map(f => f.id);
    this.supplierService.getSuppliers(30, this.showInactive, this.searchTerm, this.sortField, this.sortDirection, this.pageNumber, statuses)
      .then(suppliers => {
        for (let i = 0; i < suppliers.length; i++) {
          this.supplierSet.push(suppliers[i]);
        }
        setTimeout(() => {
          this.listHeaderService.setListTop();
          this.isLoading = false;
        }, 250);
    })
      .catch(handleError);
  }

  private resetList(): void {
    this.scrollPosition = 0;
    this.pageNumber = 0;
    this.supplierSet = [];
  }

  private setFilterOptions(): void {
		this.filterOptions = [
			{
        type: 'status',
        label: 'Status',
        options: [
          {
            text: 'Preferred',
            id: 'preferred'
          },
          {
            text: 'Neutral',
            id: 'neutral'
          },
          {
            text: 'On Watch',
            id: 'onwatch'
          }
        ]
      }
		];
	}
}
