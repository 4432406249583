import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { UtilityModule } from '@/utils/utility.module';
import { ContactModule } from '@/components/ContactComponent/contact.module';
import { SupplierComponent } from './supplier.component';
import { SupplierAddComponent } from './supplierAdd.component';
import { SupplierContactAddComponent } from './supplierContactAdd.component';
import { SupplierContactEditComponent } from './supplierContactEdit.component';
import { SupplierContactListComponent } from './supplierContactList.component';
import { SupplierEditComponent } from './supplierEdit.component';
import { SupplierEditFormComponent } from './supplierEditForm.component';
import { SupplierListComponent } from './supplierList.component';
import { FilterListModule } from './../FilterListComponent/filterList.module';
import {IMaskModule} from 'angular-imask';
@NgModule({
  declarations: [
    SupplierComponent,
    SupplierAddComponent,
    SupplierContactAddComponent,
    SupplierContactEditComponent,
    SupplierContactListComponent,
    SupplierEditComponent,
    SupplierEditFormComponent,
    SupplierListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UtilityModule,
    ContactModule,
    InfiniteScrollModule,
    FilterListModule,
    IMaskModule
  ],
  exports: [
    SupplierComponent,
    SupplierAddComponent,
    SupplierContactAddComponent,
    SupplierContactEditComponent,
    SupplierContactListComponent,
    SupplierEditComponent,
    SupplierListComponent
  ]
})
export class SupplierModule {}
