<form class="" (ngSubmit)="saveSupplier()" #supplierForm="ngForm" autocomplete="off">
    <input type="hidden" value="{{ model._id }}" />

    <div class="row">
        <div class="col-md-6">
            <fieldset class="">
                <div class="form-group">
                    <label>Name</label>
                    <div>
                        <div>
                            <input type="text" class="form-control" [(ngModel)]="model.name" name="name" required="required" #name="ngModel" initialFocus/>
                        </div>
                        <div [hidden]="(!supplierForm.form.submitted && name.pristine) || name.valid">
                            Name is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Type</label>
                    <div>
                        <button type="button" class="btn btn-default mr-1" (click)="setSupplierType('Materials')" [ngClass]="{'btn-primary active': model.type === 'Materials'}" title="Materials" tabindex="1" autofocus>Materials</button>
                        <button type="button" class="btn btn-default" (click)="setSupplierType('Outside Services')" [ngClass]="{'btn-primary active': model.type === 'Outside Services'}" title="Outside Services" tabindex="2">Outside Services</button>
                    </div>
                </div>
        
                <div class="form-group">
                    <label>Category</label>
                    <div *ngIf="model.type==='Materials'">
                        <select class="form-control" [(ngModel)]="model.category" (ngModelChange)="setCategory($event)" name="category" required="required" #category="ngModel">
                            <option value="other">Other</option>
                            <option value="aluminum">Aluminum</option>
                            <option value="glass">Glass</option>
                            <option value="sealants">Sealants</option>
                            <option value="brakemetal">Brake Metal</option>
                        </select>
                    </div>
                    <div *ngIf="model.type!='Materials'">
                        <select class="form-control" [(ngModel)]="model.category" name="category" required="required" #category="ngModel">
                            <option value="equipment">Equipment</option>
                            <option value="engineering">Engineering</option>
                            <option value="subcontractors">Subcontractors</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label>Website</label>
                    <div class="">
                        <div class="input-group">
                            <span class="input-group-addon">http://</span>
                            <input type="text" class="form-control" [(ngModel)]="model.website" name="website" #website="ngModel" />
                            <div class="input-group-btn">
                                <a href="http://{{model.website}}" target="_blank" class="btn btn-default">Go</a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Status -->
                <div class="form-group row" *ngIf="model.status">
                    <div class="col-xs-12">
                        <div>
                            <label class="control-label">Status</label>
                        </div>
                        <div>
                            <label class="custom-radio"><span class="custom-label supplier-status preferred">Preferred</span>
                                <input type="radio" name="preferred" value="preferred" [(ngModel)]="model.status">
                                <span class="radiobtn"></span>
                            </label>
                        </div>
                        <div>
                            <label class="custom-radio"><span class="custom-label supplier-status neutral">Neutral</span>
                                <input type="radio" name="neutral" value="neutral" [(ngModel)]="model.status">
                                <span class="radiobtn"></span>
                            </label>
                        </div>
                        <div>
                            <label class="custom-radio"><span class="custom-label supplier-status onwatch">On Watch</span>
                                <input type="radio" name="onwatch" value="onwatch" [(ngModel)]="model.status">
                                <span class="radiobtn"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="">
                        <label class="control-label pull-left">Notes</label>
                        <notes-field [(model)]="model.notes" [saved]="saved"></notes-field>
                    </div>
                </div>

                <div class="form-group">
                    <div>
                        <label class="custom-checkbox"><span style="font-weight:normal; font-size:16px;top: -2px; position: relative;">This supplier is active</span> <input name="isActive" type="checkbox" [(ngModel)]="model.isActive"><span class="checkmark"></span></label>                        
                    </div>
                </div>

            </fieldset>
        </div>
        <div class="col-md-6">
            <fieldset>
                <div class="form-group">
                    <label>Country</label>
                    <div class="">
                        <select class="form-control" [ngModel]="model.address.country" name="country" required="required" #country="ngModel" (ngModelChange)="validate($event)">
                            <option value="United States" selected>United States</option>
                            <option value="Canada">Canada</option>
                        </select>
                    </div>
                </div>

                <div class="form-group">
                    <label>Address 1</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.address.address1" name="address1" required="required" #address1="ngModel" />
                        <div class="alert alert-danger" [hidden]="(!supplierForm.form.submitted && address1.pristine) || address1.valid">
                            Address 1 is required
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Address 2</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.address.address2" name="address2" />
                    </div>
                </div>
                <!-- US -->
                <div class="form-group" *ngIf="model.address.country == 'United States'">
                    <label class="control-label city-st-zip"><div class="city">City</div><div class="state">State</div><div class="zip">Zip</div></label>
                    <div class="input-group full-width">
                        <input type="text" class="form-control city" [(ngModel)]="model.address.city" name="city" required="required" #city="ngModel"/>
                        <state-selector [(ngModel)]="model.address.state" name="state" required="true" #state="ngModel"></state-selector>
                        <input type="text" class="form-control zip" [(ngModel)]="model.address.zip" name="zip" required="required" #zip="ngModel" [imask]="{ mask: '00000-0000' }"/>
                    </div>
                    <div class="alert alert-danger" [hidden]="(!supplierForm.form.submitted && city.pristine) || city.valid">
                        City is required
                    </div>

                    <div class="alert alert-danger" [hidden]="(!supplierForm.form.submitted && state.pristine) || state.valid">
                        State is required
                    </div>

                    <div class="alert alert-danger" [hidden]="(!supplierForm.form.submitted && zip.pristine) || zip.valid">
                        Zip is required
                    </div>
                </div>
                <!-- Canada -->
                <div class="form-group" *ngIf="model.address.country == 'Canada'">
                    <label class="control-label"><div class="city full-width">City</div></label>
                    <input type="text" class="form-control city" [(ngModel)]="model.address.city" name="city" required="required" #city="ngModel"/>
                    <div class="alert alert-danger" [hidden]="(!supplierForm.form.submitted && city.pristine) || city.valid">
                        City is required
                    </div>
                </div>
                <div class="form-group" *ngIf="model.address.country == 'Canada'">
                    <label class="control-label province-pcode"><div class="province">Province</div><div class="postal-code">Postal Code</div></label>
                    <div class="input-group full-width">
                        <select class="form-control province" [(ngModel)]="model.address.state" name="state" required="true" #state="ngModel">
                            <option value="Alberta" selected=true>Alberta</option>
                            <option value="British Columbia">British Columbia</option>
                            <option value="Manitoba">Manitoba</option>
                            <option value="New Brunswick">New Brunswick</option>
                            <option value="Newfoundland">Newfoundland</option>
                            <option value="Northwest Territories">Northwest Territories</option>
                            <option value="Nova Scotia">Nova Scotia</option>
                            <option value="Nunavut">Nunavut</option>
                            <option value="Ontario">Ontario</option>
                            <option value="Prince Edward Island">Prince Edward Island</option>
                            <option value="Quebec">Quebec</option>
                            <option value="Saskatchewan">Saskatchewan</option>
                            <option value="Yukon Territory">Yukon Territory</option>
                        </select>                       
                        <input type="text" class="form-control postal-code" [(ngModel)]="model.address.zip" name="zip" required="required" #zip="ngModel" [imask]="{ mask: '00000-0000' }"/>
                    </div>
                    <div class="alert alert-danger" [hidden]="(!supplierForm.form.submitted && state.pristine) || state.valid">
                        Province is required
                    </div>

                    <div class="alert alert-danger" [hidden]="(!supplierForm.form.submitted && zip.pristine) || zip.valid">
                        Postal Code is required
                    </div>
                </div>
                <div class="form-group">
                    <label>Main Phone</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.main" name="main" #main="ngModel" [imask]="{ mask: '(000) 000-0000 x00000' }" validatePhone />
                    </div>
                </div>

                <div class="form-group">
                    <label>Fax</label>
                    <div class="">
                        <input type="text" class="form-control" [(ngModel)]="model.fax" name="fax" #fax="ngModel" [imask]="{ mask: '(000) 000-0000' }" validatePhone />
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
    <div class="row mt-3 mb-3" *ngIf="showAlert">
        <div class="col-12">
            <div class="alert alert-danger text-center">
                A supplier with that name already exists. <button class="btn btn-link pa-0" type="button" (click)="goToSupplier()" style="font-size:16px; padding:0;">Click to view the supplier</button>.
            </div>
        </div>
    </div>
    <div class="mb-3 mt-3">
        <button class="btn btn-primary mr-1" type="submit" [disabled]="!supplierForm.form.valid"><span *ngIf="!isNew">Save</span><span *ngIf="isNew">Create</span> supplier</button>
        <div class="inline-block pseudo-link small" (click)="cancel()">Cancel</div>
    </div>
</form>
